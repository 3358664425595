<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
    <sz-input [formControl]="filterControl" />
    <cdk-virtual-scroll-viewport
        itemSize="48"
        class="mt-2 h-[200px] max-h-[300px] min-h-[100px]"
    >
        <mat-selection-list
            [multiple]="multiple"
            (selectionChange)="handleSelectionChange($event.options)"
        >
            @for (
                device of devices | searchDevices: filterTerm;
                track device.id
            ) {
                <mat-list-option
                    class="device-option !rounded-md"
                    [checkboxPosition]="'before'"
                    [value]="device.id"
                    [selected]="selectedDeviceIds.includes(device.id)"
                >
                    <div
                        class="flex max-w-[270px] h-10 flex-row items-center justify-between gap-2 px-2"
                    >
                        <div
                            class="truncate flex-grow-0 flex-shrink"
                            [title]="device.identifier"
                        >
                            {{
                                (isAboveClient
                                    ? device.identifier
                                    : device.userIdentifier) ??
                                    device.identifier
                            }}
                        </div>
                        @if (pinnedDevices.includes(device.id)) {
                            <div class="flex items-center">
                                <mat-icon
                                    class="!h-5 !w-5 text-[goldenrod]"
                                    svgIcon="pin"
                                />
                            </div>
                        }
                    </div>
                </mat-list-option>
            }
        </mat-selection-list>
    </cdk-virtual-scroll-viewport>
    <sz-dialog-button-bar
        [primaryDisabled]="selectedDeviceIds.length === 0"
        (primaryClick)="dialogRef.close(selectedDeviceIds)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
